import React, { Component } from 'react'

export class Thanks extends Component {
  render() {
    return (
      <div>
        <h2>Tack för ditt förtroende!</h2>
        <p>Vi återkopplar så snart som möjligt.</p>
      </div>
    )
  }
}

export default Thanks