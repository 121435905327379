import React, { Component } from 'react'
import axios from 'axios'
import { fromUrl } from 'tlds';

import {
  customSentryError,
  validationRuleBoolean,
  loadingPulse,
  toggleFormAndButtons,
} from "../../utils/Utils";
import RegularForm from './RegularForm'

import * as Sentry from "@sentry/react";
import axiosRetry from 'axios-retry';
axiosRetry(axios, { retries: 3 });

Sentry.init({
  dsn: "https://c3d815e23c404acbb4db46dd2398e604@o329420.ingest.sentry.io/1845774",
  tracesSampleRate: 1.0,
});

const validateEmail = (string) => {
  // Email validation regular expression
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Test the string against the email regular expression
  if (string?.length > 0 && emailRegex.test(string)) {
    // Return true if the email is valid
    return true;
  } else {
    // Return false if the email is invalid
    return false;
  }
}

const validateLink = (link) => {
  const tlds = require('tlds');
  const tld = link.split('.').pop();
  if (tlds.includes(tld)) {
    return true;
  } else {
    return false;
  }
}

export class FormBase extends Component {
  constructor(props) {
    super(props);

    this.state = {
      form: {},
      form_uuid: "",
      formValues: {},
      form_fields: [],
      errors: [],
      loading: true,
      formSuccessfullySubmitted: false,
      standardEmptyError: `Det krävs att minst ett fält är ifyllt.`,
      errorTries: 0
    };

    this.getForm = this.getForm.bind(this);
    this.renderCorrectFormBasedOnType = this.renderCorrectFormBasedOnType.bind(this);
    this.buildLocalStorage = this.buildLocalStorage.bind(this);
    this.loadAndImplementLocalStorage = this.loadAndImplementLocalStorage.bind(this);
    this.submitForm = this.submitForm.bind(this);
    this.modifyLabelToBecomeChecked = this.modifyLabelToBecomeChecked.bind(this);
    this.setupFormField = this.setupFormField.bind(this);
    this.checkIfErrorsExist = this.checkIfErrorsExist.bind(this);
    this.submitFormRequestWithCreateAction = this.submitFormRequestWithCreateAction.bind(this);
    this.loadServerInformationIfUpdateLead = this.loadServerInformationIfUpdateLead.bind(this);

    this.timeoutID = 0;
  }

  componentDidMount() {
    this.getForm();

    if (
      window.location.href.includes("/förfrågan/") ||
      window.location.href.includes("/f%C3%B6rfr%C3%A5gan")
    ) {
      setTimeout(() => {
        this.loadServerInformationIfUpdateLead();
      }, 250);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    // Check if the 'data' property of the state has changed
    if (this.state.form !== prevState.form) {
      this.checkIfErrorsExist();
      this.loadAndImplementLocalStorage();
      this.setupFormField();
    }
  }

  // Parameters: The number of times to retry and a delay between retries in ms
  // A utility function to pause execution for a given number of milliseconds
  sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

  // Main function to get the form data
  getForm = async (retries = 3, delay = 1000) => {
    // Destructure the `formKey` from the props
    const { formKey } = this.props;

    // Internal async function that performs the actual Axios request
    const makeRequest = async (remainingRetries) => {
      try {
        // Attempt to get data
        const response = await axios.get(`/api/v1/forms/${formKey}`);

        // If successful, update the state
        this.setState({
          form: response.data.form,
          form_uuid: formKey,
          dont_touch: response.data.form.uuid,
          loading: false,
        });
        return true;
      } catch (error) {
        // If an error occurs, check if there are any retries left
        if (remainingRetries > 0) {
          // Wait before making the next request

          await this.sleep(delay);
          // Make another attempt
          return makeRequest(remainingRetries - 1);
        } else {
          // No more retries, log the error
          customSentryError(
            error,
            "Går inte att hämta formulär, ladda om sidan.",
            "components > forms > form > YourComponent.js > getForm()"
          );
          // Update the state to stop loading
          this.setState({ loading: false });
          return false;
        }
      }
    };

    // Begin the request chain with the initial number of retries
    return makeRequest(retries);
  };

  setupFormField() {
    const form = this.state.form;
    const tempStorage = [];

    form.form_sections.forEach((section) => {
      section.form_fields.forEach((field) => {
        tempStorage.push(field);
      });
    });

    this.setState({
      form_fields: tempStorage,
    });
  }

  renderCorrectFormBasedOnType(form_design) {
    if (form_design === "regular_design") {
      return (
        <RegularForm
          form={this.state.form}
          errors={this.state.errors}
          buildLocalStorage={this.buildLocalStorage}
          submitForm={this.submitForm}
          formSuccessfullySubmitted={this.state.formSuccessfullySubmitted}
          errorTries={this.state.errorTries}
        />
      );
    } else {
      return <div>Formuläret är inte designat än</div>;
    }
  }

  modifyLabelToBecomeChecked() {
    const checkboxes = document.querySelectorAll(`input[type="checkbox"]`);
    checkboxes.forEach((checkbox) => {
      const label = checkbox.closest(".CheckboxField");
      if (label != undefined) {
        if (checkbox.checked) {
          label.classList.add("checked-label");
        } else {
          label.classList.remove("checked-label");
        }
      }
    });
  }

  loadServerInformationIfUpdateLead() {
    var self = this;
    const secure_id = window.location.pathname.split(
      "/f%C3%B6rfr%C3%A5gan/"
    )[1];

    axios
      .get(`/api/v1/load-customer/${secure_id}`)
      .then((response) => {
        localStorage.setItem(
          `formValues_${self.state.form_uuid}`,
          JSON.stringify(response.data.data)
        );
        localStorage.setItem(
          `customer_secure_id_${self.state.form_uuid}`,
          `${secure_id}`
        );
        self.loadAndImplementLocalStorage();
      })
      .catch((error) => {
        customSentryError(
          error,
          "Går inte att hämta formulär, ladda om sidan. ",
          "components > forms > form > FormBase.js > loadServerInformationIfUpdateLead()"
        );
      });
  }

  loadAndImplementLocalStorage() {
    const formValues = JSON.parse(
      localStorage.getItem(`formValues_${this.state.form_uuid}`)
    );
    if (localStorage.getItem(`formValues_${this.state.form_uuid}`) === null) {
      return;
    }

    if (formValues) {
      this.setState({ formValues });
    }

    // Implement form values from localStorage
    Object.keys(formValues).forEach((sectionId) => {
      Object.keys(formValues[sectionId]).forEach((fieldId) => {
        const fieldUUid = fieldId.split("form_field_")[1];
        const field = document.querySelector(
          `[data-form-field-uuid="${fieldUUid}"]`
        );

        if (field != undefined) {
          const input = field.querySelector(
            "input, textarea, select, radio, checkbox"
          );

          if (
            input.type === "text" ||
            input.type === "email" ||
            input.type === "number" ||
            input.type === "tel" ||
            input.type === "date" ||
            input.type === "time" ||
            input.type === "textarea"
          ) {
            input.value = formValues[sectionId][fieldId];
          } else if (
            (formValues[sectionId][fieldId] != "" &&
              input.type === "checkbox") ||
            input.type === "radio"
          ) {
            Object.keys(formValues[sectionId][fieldId]).forEach(
              (multipleChoiceId) => {
                const multipleChoiceUUid =
                  multipleChoiceId.split("multiple_choice_")[1];
                const multipleChoice = field.querySelector(
                  `[data-multiple-choice-uuid="${multipleChoiceUUid}"]`
                );
                if (multipleChoice != undefined) {
                  const checkboxInput = multipleChoice.querySelector(
                    "input[type='checkbox'], input[type='radio']"
                  );
                  checkboxInput.checked =
                    formValues[sectionId][fieldId][multipleChoiceId];
                }
              }
            );
          } else {
          }
        }
      });
    });

    this.modifyLabelToBecomeChecked();
  }

  getMultipleChoiceValue(sectionId, fieldId, multipleChoiceId) {
    const myDataString = localStorage.getItem(
      `formValues_${this.state.form_uuid}`
    );
    if (myDataString) {
      const myDataObject = JSON.parse(myDataString);
      if (
        myDataObject &&
        myDataObject[sectionId] &&
        myDataObject[sectionId][fieldId] &&
        myDataObject[sectionId][fieldId][multipleChoiceId]
      ) {
        return myDataObject[sectionId][fieldId][multipleChoiceId];
      } else {
        return "";
      }
    } else {
      return "";
    }
  }

  buildLocalStorage(e) {
    var self = this;
    const inputElement = e.target;
    const sectionId = `form_section_${inputElement
      .closest(".FormSection")
      .getAttribute("data-form-section-uuid")}`;
    const fieldId = `form_field_${inputElement
      .closest(".FormField")
      .getAttribute("data-form-field-uuid")}`;

    const multipleChoiceChecker = inputElement
      .closest(".MultiBox")
      ?.getAttribute("data-multiple-choice-uuid");
    const multipleChoiceType = inputElement
      .closest(".MultiBox")
      ?.getAttribute("data-multiple-choice-type");
    const multipleChoiceId = `multiple_choice_${inputElement
      .closest(".MultiBox")
      ?.getAttribute("data-multiple-choice-uuid")}`;
    const value = inputElement.value;

    let formValues = { ...this.state.formValues };

    if (multipleChoiceType === "single") {
      let setValueMultipleChoice = "";
      if (
        this.getMultipleChoiceValue(sectionId, fieldId, multipleChoiceId) ===
        value
      ) {
        setValueMultipleChoice = "";
      } else {
        setValueMultipleChoice = value;
      }

      formValues = {
        ...formValues,
        [sectionId]: {
          ...formValues[sectionId],
          [fieldId]: {
            [multipleChoiceId]: setValueMultipleChoice,
          },
        },
      };
    } else if (multipleChoiceChecker) {
      // Update for MultipleChoice
      let setValueMultipleChoice = "";
      if (
        this.getMultipleChoiceValue(sectionId, fieldId, multipleChoiceId) ===
        value
      ) {
        setValueMultipleChoice = "";
      } else {
        setValueMultipleChoice = value;
      }

      // Update the object without adding the multipleChoiceId yet
      formValues = {
        ...formValues,
        [sectionId]: {
          ...formValues[sectionId],
          [fieldId]: {
            ...formValues[sectionId]?.[fieldId],
          },
        },
      };

      // Only add the multipleChoiceId if the setValueMultipleChoice is not empty
      if (setValueMultipleChoice !== "") {
        formValues[sectionId][fieldId][multipleChoiceId] =
          setValueMultipleChoice;
      } else {
        // Remove the key if it exists and the value is empty
        if (formValues[sectionId][fieldId][multipleChoiceId]) {
          delete formValues[sectionId][fieldId][multipleChoiceId];
        }
      }
    } else {
      // Update for FormField
      formValues = {
        ...formValues,
        [sectionId]: {
          ...formValues[sectionId],
          [fieldId]: value,
        },
      };
    }

    this.setState({ formValues }, () => {
      setTimeout(() => {
        self.checkIfErrorsExist();
        self.modifyLabelToBecomeChecked();
      }, 25);
    });

    // Save form values to localStorage
    localStorage.setItem(
      `formValues_${this.state.form_uuid}`,
      JSON.stringify(formValues)
    );

    if (this.timeoutID) clearTimeout(this.timeoutID);
    this.timeoutID = setTimeout(() => {
      if (!localStorage.getItem(`customer_secure_id_${this.state.form_uuid}`)) {
        this.submitFormRequestInBackground();
      } else if (
        localStorage.getItem(`customer_secure_id_${this.state.form_uuid}`) ==
        "null"
      ) {
        this.submitFormRequestInBackground();
      } else {
        this.updateFormRequestInBackground();
      }
    }, 250);
  }

  checkIfErrorsExist() {
    var self = this;
    const formValues = this.state.formValues;
    const form_fields = this.state.form_fields;

    for (const property in formValues) {
      for (const loop_form_field in formValues[property]) {
        let formFieldUUID = loop_form_field.replace("form_field_", "");

        const index = form_fields.findIndex(
          (formField) => formField.uuid === formFieldUUID
        );
        let formFieldValidationArray = [];

        if (index >= 0) {
          const foundField = form_fields[index];
          const required_field_message = `Fältet är obligatoriskt.`;

          if (
            foundField.question_type === "QUESTION_TYPE_LONG_ANSWER" ||
            foundField.question_type === "QUESTION_TYPE_SHORT_ANSWER" ||
            foundField.question_type === "QUESTION_TYPE_EMAIL" ||
            foundField.question_type === "QUESTION_TYPE_PHONE_NUMBER" ||
            foundField.question_type === "QUESTION_TYPE_DATE" ||
            foundField.question_type === "QUESTION_TYPE_LINK" ||
            foundField.question_type === "QUESTION_TYPE_TIME" ||
            foundField.question_type === "QUESTION_TYPE_NUMBER" ||
            foundField.question_type === "QUESTION_TYPE_ADDRESS" ||
            foundField.question_type === "QUESTION_TYPE_CUSTOM_CODE"
          ) {
            if (foundField.question_type === "QUESTION_TYPE_LINK") {
              let requireLinkField =
                validationRuleBoolean(foundField, "required") &&
                formValues[property][loop_form_field] === "";

              if (requireLinkField) {
                formFieldValidationArray.push(`${required_field_message}`);
              }

              if (
                !validateLink(formValues[property][loop_form_field]) &&
                formValues[property][loop_form_field] !== ""
              ) {
                formFieldValidationArray.push(`Det krävs en giltig länk`);
              }
            } else if (foundField.question_type === "QUESTION_TYPE_EMAIL") {
              console.log(`QUESTION_TYPE_EMAIL`);
              let requiredEmailField =
                validationRuleBoolean(foundField, "required") &&
                formValues[property][loop_form_field] === "";

              if (requiredEmailField) {
                formFieldValidationArray.push(`${required_field_message}`);
              }
              console.log(
                `formFieldValidationArray: ${formFieldValidationArray}`
              );

              if (
                formValues[property][loop_form_field] !== "" &&
                !validateEmail(formValues[property][loop_form_field])
              ) {
                formFieldValidationArray.push(
                  `Det krävs en giltig e-postadress`
                );
              }
            } else {
              if (
                validationRuleBoolean(foundField, "required") &&
                formValues[property][loop_form_field] === ""
              ) {
                formFieldValidationArray.push(`${required_field_message}`);
              }
            }
          } else if (
            foundField.question_type === "QUESTION_TYPE_MULTIPLE_CHOICES"
          ) {
            let countMultipleChoicesChecked = 0;
            for (const multipleChoice in formValues[property][
              loop_form_field
            ]) {
              if (formValues[property][loop_form_field][multipleChoice] != "") {
                countMultipleChoicesChecked++;
              }
            }

            if (
              validationRuleBoolean(foundField, "required") &&
              countMultipleChoicesChecked === 0
            ) {
              formFieldValidationArray.push(`${required_field_message}`);
            }
          } else if (
            foundField.question_type === "QUESTION_TYPE_SINGLE_CHOICE"
          ) {
            let checkerSingleChoice = false;
            for (const multipleChoice in formValues[property][
              loop_form_field
            ]) {
              if (formValues[property][loop_form_field][multipleChoice] != "") {
                checkerSingleChoice = true;
              }
            }

            if (
              validationRuleBoolean(foundField, "required") &&
              checkerSingleChoice === false
            ) {
              formFieldValidationArray.push(`${required_field_message}`);
            }
          } else {
          }

          if (formFieldValidationArray.length > 0) {
            let errorExists = self.state.errors.some((errorObj) => {
              return errorObj.hasOwnProperty(formFieldUUID);
            });

            if (!errorExists) {
              self.setState({
                ...self.state,
                errors: [
                  ...self.state.errors,
                  { [formFieldUUID]: formFieldValidationArray },
                ],
              });
            } else {
              console.log("UUID already exists in the errors array.");
            }
          } else {
            // Remove the formFieldUUID object from the errors array if the validation passes
            const updatedErrors = self.state.errors.filter((errorObj) => {
              return !errorObj.hasOwnProperty(formFieldUUID);
            });

            self.setState({
              ...self.state,
              errors: updatedErrors,
            });
          }
        }
      }
    }
  }

  updateFormRequestInBackground = async () => {
    var self = this;
    const formValues = this.state.formValues;
    const secure_id = localStorage.getItem(
      `customer_secure_id_${this.state.form_uuid}`
    );
    const dont_touch = document.querySelector(
      `[data-form-uuid="${this.state.form_uuid}"] input[name="dont_touch"]`
    );
    try {
      axios
        .post(`/api/v1/form-submission/${secure_id}`, {
          form: formValues,
          dont_touch: dont_touch.value,
        })
        .then((response) => {
          // window.smartproduktion.showNotification(`UPPDATERALaddade upp i BG: ${response.data.customer_secure_id}`)
        })
        .catch((error) => {
          customSentryError(
            error,
            "",
            "components > forms > form > FormBase.js > updateFormRequestInBackground()"
          );
        });
    } catch (error) {
      customSentryError(
        error,
        "Något gick fel",
        "components > forms > form > FormBase.js > TRY > submitFormRequestInBackground()"
      );
    }
  };

  submitFormRequestInBackground = async () => {
    var self = this;
    const dont_touch = document.querySelector(
      `[data-form-uuid="${this.state.form_uuid}"] input[name="dont_touch"]`
    );
    try {
      axios
        .post(`/api/v1/form-submission`, {
          uuid: this.state.form.uuid,
          form: this.state.formValues,
          dont_touch: dont_touch.value,
        })
        .then((response) => {
          if (response.data.customer_secure_id != undefined) {
            localStorage.setItem(
              `customer_secure_id_${this.state.form_uuid}`,
              `${response.data.customer_secure_id}`
            );
          } else {
            alert("Något gick fel...");
            console.log(response);
            console.log(response.data);
          }
        })
        .catch((error) => {
          customSentryError(
            error,
            "Något gick fel",
            "components > forms > form > FormBase.js > submitFormRequestInBackground()"
          );
        });
    } catch (error) {
      customSentryError(
        error,
        "Något gick fel",
        "components > forms > form > FormBase.js > TRY > submitFormRequestInBackground()"
      );
    }
  };

  submitFormRequestWithCreateAction = async () => {
    var self = this;
    const dont_touch = document.querySelector(
      `[data-form-uuid="${this.state.form_uuid}"] input[name="dont_touch"]`
    ).value;
    const secureID = localStorage.getItem(
      `customer_secure_id_${this.state.form_uuid}`
    );
    const uuid = this.state.form.uuid;

    if (secureID === null || secureID === undefined || secureID === "") {
      setTimeout(() => {
        self.submitFormRequestWithCreateAction();
        return;
      }, [250, 500, 1000]);
    } else {
      try {
        axios
          .post(`/api/v1/form-submission/submit/${secureID}`, {
            form: this.state.formValues,
            dont_touch: dont_touch,
          })
          .then((response) => {
            if (response.data.customer_id != undefined) {
              document.querySelector(`body`).scrollIntoView();
              window.smartproduktion.showNotification(`Vi har skickat in din förfrågan. `);
              
              const form = document.querySelector(`#JsForm_${uuid}`);
              form.classList.add("hidden");
              self.setState({
                formSuccessfullySubmitted: true,
                formValues: {},
                errors: [],
              });
              localStorage.removeItem(`formValues_${this.state.form_uuid}`);
              localStorage.removeItem(`customer_secure_id_${this.state.form_uuid}`);

              // Get localStorage that start with DynamicPopup_ and set it to true
              const interactiveDynamicPopup = document.querySelector(".InteractiveDynamicPopup");
              if (interactiveDynamicPopup) {
                const uniqueIdentifier = interactiveDynamicPopup.dataset.cookieUniqueIdentifier;

                localStorage.setItem(`${uniqueIdentifier}`, "true");
                interactiveDynamicPopup.classList.add("hidden");
              }

              window.location.href = `/tack?uuid=${self.state.form.uuid}&customer_id=${response.data.customer_id}`;
            } else {
              alert("Något gick fel: Skicka in förfrågan igen.");
            }
          })
          .catch((error) => {
            window.smartproduktion.showNotification(
              `${error?.response.data?.errors[0]}`,
              true
            );

            customSentryError(
              error,
              "Något gick fel",
              "components > forms > form > FormBase.js > submitFormRequestWithCreateAction()"
            );
          });
      } catch (error) {
        customSentryError(
          error,
          "Något gick fel",
          "components > forms > form > FormBase.js > TRY > submitFormRequestWithCreateAction()"
        );
      }
    }
  };


  submitForm(e) {
    e.preventDefault();
    var self = this;
    toggleFormAndButtons(`#${e.target.id}`, true, "Skickar...");

    // Check if at least one value is present in formValues
    const hasValue = Object.values(this.state.formValues).some((section) =>
      Object.values(section).some((field) => field !== "")
    );

    if (!hasValue) {
      window.smartproduktion.showNotification( this.state.standardEmptyError, true );
      this.setState({errorTries: self.state.errorTries + 1});
    }

    const requiredFormFields = this.state.form_fields
      .filter((field) => {
        return (
          validationRuleBoolean(field, "required") &&
          field.validation_rules["required"]["value"] === true
        );
      })
      .map(function (obj) {
        return obj.uuid;
      });

    const activeFormFieldsInLocalStorageThatIsRequired = [];
    Object.keys(self.state.formValues).map((formSection) => {
      return Object.keys(self.state.formValues[formSection]).map(
        (formField) => {
          const formFieldUUID = formField.split("form_field_")[1];
          const getFormFieldFromState = self.state.form_fields.find( (field) => field.uuid === formFieldUUID);
          if (
            validationRuleBoolean(getFormFieldFromState, "required") &&
            getFormFieldFromState.validation_rules["required"]["value"] === true
          ) {
            activeFormFieldsInLocalStorageThatIsRequired.push(formFieldUUID);
            if (!self.state.formValues[formSection][formField]) {
              const existingError = self.state.errors.find((error) => error.hasOwnProperty(formFieldUUID) );
              self.setState({errorTries: self.state.errorTries + 1,});
              if (existingError == undefined) {
                self.setState({
                  ...self.state,
                  errors: [
                    ...self.state.errors,
                    { [formFieldUUID]: [`Fältet är obligatoriskt.`] },
                  ]
                });
              }
            }
          }
        }
      );
    });

    let differenceBetweenTheRequiredAndLocalStorage = requiredFormFields.filter(
      function (item) {
        return !activeFormFieldsInLocalStorageThatIsRequired.includes(item);
      }
    );

    if (differenceBetweenTheRequiredAndLocalStorage.length > 0) {
      const tempErrors = [];
      differenceBetweenTheRequiredAndLocalStorage.map((formFieldUUID) => {
        const existingError = self.state.errors.find((error) =>
          error.hasOwnProperty(formFieldUUID)
        );
        if (existingError == undefined) {
          tempErrors.push({ [formFieldUUID]: [`Fältet är obligatoriskt.`] });
        }
      });
      self.setState({
        errors: [...self.state.errors, ...tempErrors],
      });
    }


    setTimeout(() => {
      if (!hasValue) {
        window.smartproduktion.showNotification(
          this.state.standardEmptyError,
          true
        );
      }
      else if (self.state.errors.length > 0) {
        const errorUUID = Object.keys(this.state.errors[0])[0];
        document.getElementById(`FormField_${errorUUID}`).scrollIntoView();
        window.smartproduktion.showNotification(
          `Fyll i alla obligatoriska fält.`,
          true
        );
        toggleFormAndButtons(`#${e.target.id}`, false);
      } else {
        self.submitFormRequestWithCreateAction();
      }
    }, 0);

    setTimeout(() => {
      toggleFormAndButtons(`#${e.target.id}`, false);
    }, 750);

  }

  render() {
    const form = this.state.form;
    return (
      <div data-form-uuid={this.state.form_uuid}>
        {this.state.loading && loadingPulse(this.state.loading)}
        {form && form.form_design && this.renderCorrectFormBasedOnType(form.form_design)}
      </div>
    );
  }
}

export default FormBase;