import React, { Component } from "react";

import {
  designAttributesBoolean,
  colSpanBasedOnFormField,
} from "../../utils/Utils";

import TextField from "./field-type/TextField";
import NumberField from "./field-type/NumberField";
import LinkField from "./field-type/LinkField";
import EmailField from "./field-type/EmailField";
import PhoneField from "./field-type/PhoneField";
import LongTextField from "./field-type/LongTextField";
import AddressField from "./field-type/AddressField";
import MultipleBoxes from "./field-type/MultipleBoxes";
import CustomCodeField from "./field-type/CustomCodeField";
import DontTouchThis from "./field-type/DontTouchThis";

import Thanks from "./Thanks";

export class RegularForm extends Component {
  renderCorrectFieldType(form_field, question_type) {
    if (question_type === "QUESTION_TYPE_SHORT_ANSWER") {
      return <TextField errors={this.props.errors} form_field={form_field} />;
    } 
    else if (question_type === "QUESTION_TYPE_LONG_ANSWER") {
      return <LongTextField errors={this.props.errors} form_field={form_field} />
    } 
    else if (question_type === "QUESTION_TYPE_NUMBER") {
      return <NumberField errors={this.props.errors} form_field={form_field} />;
    } 
    else if (question_type === "QUESTION_TYPE_EMAIL") {
      return <EmailField errors={this.props.errors} form_field={form_field} />;
    } 
    else if (question_type === "QUESTION_TYPE_ADDRESS") {
      return <AddressField errors={this.props.errors} form_field={form_field} />
    } 
    else if (question_type === "QUESTION_TYPE_CUSTOM_CODE") {
      return <CustomCodeField errors={this.props.errors} form_field={form_field} />
    } 
    else if (question_type === "QUESTION_TYPE_PHONE_NUMBER") {
      return <PhoneField errors={this.props.errors} form_field={form_field} />;
    } 
    else if (question_type === "QUESTION_TYPE_LINK") {
      return <LinkField errors={this.props.errors} form_field={form_field} />;
    } 
    else if (question_type === "QUESTION_TYPE_MULTIPLE_CHOICES") {
      return <MultipleBoxes errors={this.props.errors} form_field={form_field} />
    } 
    else if (question_type === "QUESTION_TYPE_SINGLE_CHOICE") {
      return <MultipleBoxes errors={this.props.errors} form_field={form_field} />
    } 
    else {
      return "FEL TYP AV FRÅAG????";
    }
  }

  render() {
    const form = this.props.form;
    return (
      <div key={`RegularForm_${form.uuid}`} className="RegularForm">
        {this.props.formSuccessfullySubmitted && <Thanks />}
        <form
          key={`JsForm_${form.uuid}`}
          id={`JsForm_${form.uuid}`}
          className={`JsForm pb-20 md:pb-40`}
          onChange={(e) => this.props.buildLocalStorage(e)}
          onSubmit={(e) => this.props.submitForm(e)}
        >
          <DontTouchThis uuid={form.uuid} />

          {form.form_sections
            .sort((a, b) => a.display_order - b.display_order)
            .map((form_section, index) => {
              return (
                <div
                  key={`form_section_${form_section.uuid}`}
                  className={`FormSection`}
                  id={form_section.uuid}
                  data-form-section-uuid={form_section.uuid}
                >
                  {form_section.title?.length > 0 && (
                    <div className="col-span-12 SP-common-text pb-0 mb-0">
                      {form_section.title?.length > 0 && (
                        <h3>{form_section.title}</h3>
                      )}
                      {form_section.description?.length > 0 && (
                        <p className="pb-0">{form_section.description}</p>
                      )}
                    </div>
                  )}
                  {form_section.form_fields &&
                    form_section.form_fields.length > 0 && (
                      <div
                        className={`FormSectionFormFields pb-12 grid grid-cols-12 gap-5 gap-x-4 md:gap-x-8 ${
                          index === 0 &&
                          (form_section.title == null ||
                            form_section.title?.length === 0)
                            ? ""
                            : "pt-2 "
                        }`}
                      >
                        {form_section.form_fields
                          .sort((a, b) => a.display_order - b.display_order)
                          .map((form_field, index) => {
                            return this.renderCorrectFieldType(
                              form_field,
                              form_field.question_type
                            );
                          })}
                      </div>
                    )}
                </div>
              );
            })}

          <button type="submit" className="btn btn-medium btn-regular">
            { form.submit_form_button?.length > 0 ? form.submit_form_button : "Skicka in" }
          </button>

          {this.props.errorTries >= 5 &&  this.props.errors.length > 0 && 
            <div className="pt-3">
              <p
                className="RegularFormTerms small-text text-red-500"
                style={{ fontSize: "12px" }}
              >
                Det verkar vara något i formuläret som inte stämmer. Kontrollera att du har fyllt i alla fält korrekt.
              </p>
            </div>
          }

          {form.terms?.length > 0 && (
            <div className="pt-3">
              <p
                className="RegularFormTerms small-text text-gray-500"
                style={{ fontSize: "12px" }}>
                {form.terms}
              </p>
            </div>
          )}
        </form>
      </div>
    );
  }
}

export default RegularForm;
