import React, { Component } from 'react'
// import { inputTextStyles, requiredField, validationRuleBoolean, svgShortFormContent, labelStyles, colSpanBasedOnFormField, designAttributesBoolean } from '../../../utils/Utils'

export class Radio extends Component {
  render() {
    const multiple_choice = this.props.multiple_choice
    const form_field = this.props.form_field
    return (
      <div 
        key={`RadioField_${multiple_choice.uuid}`}
        className="
          RadioField
        ">
          <div className="relative">
            <div className="flex items-center ">
              <input 
                  type="radio" 
                  id={multiple_choice.uuid}
                  name={form_field.uuid} 
                  placeholder={multiple_choice.choice_value} 
                  defaultValue={multiple_choice.choice_value || ""}     
              />
              <label 
                className='bold text-md flex items-center w-full max-w-full truncate'
                style={{ height: "30px"}}
                htmlFor={multiple_choice.uuid}>{multiple_choice.choice_value}</label>
          </div>
        </div>
      </div>
    )
  }
}

export default Radio