import React from 'react';

function Icon({ icon, size }) {
  const strokeWidthPercentage = 3.5;
  const updatedSvg = icon.svg
      .replace(`<svg`, `<svg width='${size}px' height='${size}px'`)
      .replace(`<rect`, `<rect width='${size}px' height='${size}px'`)
      .replace(/<defs>[\s\S]*?<\/defs>/i, '')
      .replace(/<g\s*clip-path="[^"]*">([\s\S]*?)<\/g>/i, '$1')
      .replaceAll(`strokeWidth='1'`, `stroke-width='${strokeWidthPercentage}%'`)
      .replaceAll(`strokeWidth="1"`, `stroke-width='${strokeWidthPercentage}%'`)
      .replaceAll(`stroke-width='1'`, `stroke-width='${strokeWidthPercentage}%'`)
      .replaceAll(`stroke-width="1"`, `stroke-width='${strokeWidthPercentage}%'`)
      .replaceAll(`<circle`, `<circle stroke-width='${strokeWidthPercentage}%'`)
      .replaceAll(`<path`, `<path stroke-width='${strokeWidthPercentage}%'`)
      .replaceAll(`<line`, `<line stroke-width='${strokeWidthPercentage}%'`)

  return (
    <div className='DynamicIcon noselect' dangerouslySetInnerHTML={{ __html: updatedSvg }} />
  );
}

export default Icon;