import React, { Component } from "react";
import {
  labelStyles,
  requiredField,
  validationRuleBoolean,
  designAttributesBoolean,
  colSpanBasedOnFormField,
} from "../../../utils/Utils";

import Checkbox from "./Checkbox";
import Radio from "./Radio";
import Error from "../Error";
import Helpful from "../Helpful";

export class MultipleBoxes extends Component {
  render() {
    const form_field = this.props.form_field;
    const singleOrMultiple =
      form_field.question_type === "QUESTION_TYPE_MULTIPLE_CHOICES"
        ? "multiple"
        : "single";

    return (
      <div
        className="FormField pt-3 pb-2 col-span-12"
        data-form-field-uuid={form_field.uuid}
        key={`MultipleBoxes_${form_field.uuid}_${singleOrMultiple}`}
        id={`FormField_${form_field.uuid}`}
      >
        <div className="relative pr-3" style={{ display: "inline-block" }}>
          <h5
            className={`${labelStyles()} label ${
              form_field.help_text?.length > 0 && "mb-2 pb-0"
            }`}
            style={{ display: "inline-block" }}
          >
            {" "}
            {form_field.field_title}
          </h5>
          <div className="span zoom-07">
            {validationRuleBoolean(form_field, "required") && requiredField()}
          </div>
        </div>
        <div>
          <Helpful form_field={form_field} />
        </div>
        <div className="grid grid-cols-12 gap-4 md:gap-4 pt-2">
          { 
            form_field.multiple_choices &&
            form_field.multiple_choices
              .sort((a, b) => a.display_order - b.display_order)
              .map((option, index) => {
                if (
                  option.choice_value != null &&
                  option.choice_value.length > 0
                ) {
                  return (
                    <div
                      className={`MultiBox relative ${
                        designAttributesBoolean(form_field, "field_columns") &&
                        colSpanBasedOnFormField(
                          form_field.design_attributes["field_columns"]
                        )
                      }`}
                      data-multiple-choice-uuid={option.uuid}
                      data-multiple-choice-type={singleOrMultiple}
                      key={`multiple_option_${option.uuid}_${index}`}
                    >
                    {
                      singleOrMultiple === "multiple" ?
                        <Checkbox
                          form_field={form_field}
                          multiple_choice={option}
                        />
                      : 
                      <Radio
                        form_field={form_field}
                        multiple_choice={option}
                      />
                    }
                  </div>
                );
              }
            })
          }
        </div>

        {this.props.errors && this.props.errors.length > 0 && (
          <Error form_field={form_field} errors={this.props.errors} />
        )}
      </div>
    );
  }
}

export default MultipleBoxes;
