import FormBase from '../components/forms/form/FormBase.js';
import { renderComponentWithTurbo } from '../components/utils/Utils';


const formComponents = [];
  
// Get all form containers on the page
const formContainers = document.querySelectorAll('.regular-on-page-form');

// Convert the formContainers NodeList to an array and iterate over each form container
Array.from(formContainers).map((formContainer) => {
  formComponents.push(new FormBase(formContainer));
  // Get the unique identifier of the form
  const formKey = formContainer.dataset.uuid;

  // Check if the form has already been rendered
  renderComponentWithTurbo(FormBase, `.regular-on-page-form[data-uuid="${formKey}"]`, { formKey });
});
