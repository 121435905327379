import React, { Component } from "react";
import {
  inputTextStyles,
  designAttributesBoolean,
  requiredField,
  validationRuleBoolean,
} from "../../../utils/Utils";
import Icon from "../../../utils/Icon";

export class Checkbox extends Component {
  render() {
    const multiple_choice = this.props.multiple_choice;
    const justCheckbox = (
      <div
        key={`JustCheckbox${multiple_choice.uuid}`}
        className="flex items-center SpecificCheckbox"
         style={{ maxWidth: "100%"}}
      >
        <input
          type="checkbox"
          id={multiple_choice.uuid}
          name={multiple_choice.uuid}
          placeholder={multiple_choice.choice_value}
          defaultValue={multiple_choice.choice_value || ""}
        />
        <h5 className="mb-0 pb-0 truncate noselect pointer-events-none max-w-full">
          {multiple_choice.choice_value}
        </h5>
      </div>
    );

    const visibleIconsOrNot =
      designAttributesBoolean(this.props.form_field, "field_remove_icon") &&
      this.props.form_field.design_attributes["field_remove_icon"] == "true"
        ? "active"
        : "";

    const withOrWithoutImageOrIcon =
      multiple_choice.icon_id != null && visibleIconsOrNot ? true : false;
    const leftOrCenter =
      withOrWithoutImageOrIcon &&
      designAttributesBoolean(this.props.form_field, "field_alignment") &&
      this.props.form_field.design_attributes["field_alignment"] === "left"
        ? "BoxLeft"
        : "BoxCenter";

    return (
      <div 
        className={`CheckboxField ${ withOrWithoutImageOrIcon ? "CheckboxWithIcon" : "" }`} 
        key={`CheckboxField_${multiple_choice.uuid}`}
      >
        <div className="relative">
          <label
            className={`bold text-md flex items-center ${
              withOrWithoutImageOrIcon
                ? "LabelForCheckboxWithIcon"
                : "LabelNoIcon"
            }`}
            style={{ height: withOrWithoutImageOrIcon ? "auto" : "35px" }}
            htmlFor={multiple_choice.uuid}
          >
            {withOrWithoutImageOrIcon && (
              <div className={`BoxWithIcon ${leftOrCenter}`}>
                <Icon icon={multiple_choice.icon} size="24" />
                {justCheckbox}
              </div>
            )}
            {!withOrWithoutImageOrIcon && <>{justCheckbox}</>}
          </label>
        </div>
      </div>
    );
  }
}

export default Checkbox;
