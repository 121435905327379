import React, { Component } from "react";
import {
  inputTextStyles,
  labelStyles,
  requiredField,
  validationRuleBoolean,
  svgShortFormContent,
  designAttributesBoolean,
  colSpanBasedOnFormField,
} from "../../../utils/Utils";

import Error from "../Error";
import Helpful from "../Helpful";

export class CustomCodeField extends Component {
  render() {
    const form_field = this.props.form_field;
    return (
      <div
        id={`FormField_${form_field.uuid}`}
        key={`CustomCodeField_${form_field.uuid}`}
      >
        <input
          type="text"
          id={form_field.uuid}
          name={form_field.uuid}
          placeholder={form_field.field_placeholder || ""}
          className={inputTextStyles()}
          defaultValue={form_field.field_default_value || ""}
        />
      </div>
    );
  }
}

export default CustomCodeField;
