import React, { Component } from 'react'
import axios from 'axios'

export class DontTouchThis extends Component {
  constructor(props) {
    super(props)

    this.state = {
      invisibleCaptchaId: ""
    }
  }

  componentDidMount(){
    this.setState({ invisibleCaptchaId: this.props.uuid })
  }

  shouldComponentUpdate(nextProps, nextState) {
    // Only update the component if the 'data' property of the state has changed
    return nextState.invisibleCaptchaId !== this.state.invisibleCaptchaId;
  }
  
  render() {
    return (
      <div>
        <div>
            <input 
              type="text" 
              className='hidden'
              defaultValue={this.state.invisibleCaptchaId} 
              name='dont_touch'
            />
          </div>
      </div>
    )
  }
}

export default DontTouchThis