import React, { Component } from "react";
import {
  labelStyles,
  inputTextStyles,
  requiredField,
  svgLongFormContent,
  designAttributesBoolean,
  colSpanBasedOnFormField,
  validationRuleBoolean,
  customFormFieldHelpTextChecker,
} from "../../../utils/Utils";

import Error from "../Error";
import Helpful from "../Helpful";

export class LongTextField extends Component {
  render() {
    const form_field = this.props.form_field;
    return (
      <div
        key={`LongTextField_${form_field.uuid}`}
        id={`FormField_${form_field.uuid}`}
        className={`FormField pb-4 ${
          designAttributesBoolean(form_field, "field_columns") &&
          colSpanBasedOnFormField(form_field.design_attributes["field_columns"])
        }`}
        data-form-field-uuid={form_field.uuid}
      >
        <div className="relative">
          <label htmlFor={form_field.uuid} className={labelStyles()}>
            {form_field.field_title}
          </label>
          {customFormFieldHelpTextChecker(form_field) && (
            <div className="mt-1.5">
              <Helpful form_field={form_field} />
            </div>
          )}
          <div className="relative">
            <textarea
              type="text"
              id={form_field.uuid}
              name={form_field.uuid}
              placeholder={form_field.field_placeholder || ""}
              className={inputTextStyles()}
              defaultValue={form_field.field_default_value || ""}
              style={{ maxHeight: "auto", minHeight: "100px" }}
            ></textarea>

            {this.props.errors && this.props.errors.length > 0 && (
              <Error form_field={form_field} errors={this.props.errors} />
            )}

            {validationRuleBoolean(form_field, "required") && requiredField()}

            <div className="absolute top-2.5 right-3">
              {svgLongFormContent("fill-gray")}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default LongTextField;
