import React, { Component } from 'react'

export class Error extends Component {
  render() {
    const form_field = this.props.form_field
    const errors = this.props.errors
  
    const fieldErrors = errors.filter((errorObj) => {
      return form_field.uuid === Object.keys(errorObj)[0];
    });

    return (
      <>{ fieldErrors && <div key={`error_${form_field.uuid}`} className=''>
          <div className=''>
            {
              fieldErrors.map((errorObject, index) => {
                return Object.values(errorObject)[0].map((error_message) => {
                  return <p key={`error_${error_message}`} className='mt-1.5 mb-0 pb-0 bold' style={{color: "rgb(178, 1, 16)", fontSize: "12px"}}>
                    {error_message}
                  </p>
                })
              })
            }
          </div>
        </div>
        }
      </>
    )
  }
}

export default Error