import React, { Component } from 'react'

import { customFormFieldHelpTextChecker } from "../../utils/Utils";

export class Helpful extends Component {
  
  render() {
    const form_field = this.props.form_field;
    return (
      customFormFieldHelpTextChecker(form_field) && <div className={`Helpful Helpful${form_field.id} pb-1`}>
        <p className="mb-0 pb-0 small-text">{form_field.help_text}</p>
      </div>
    );
  }
}

export default Helpful